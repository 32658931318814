nav{
    width: 100%;
    background: var(--background-color);
    color: black;
    padding:2px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}
.logo{
    width: 75px;
    margin-left: 4%;
}
nav ul li {
    display: inline-block;
    list-style: none;
    margin: 2px 23px;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    position: relative;
}
.active, nav ul li:hover{
    transition: 0.5s;    
}
nav ul li:hover{
    color: var(--primary-color);
}

.toggle{
    font-size: 30px;
    display: none;
}
 .dropdown-menu{
    display: none;
    
}
.dropdown-menu ul li{
    display: block;
    padding: 8px 2px;
    font-size: 16px;
   
}
nav ul li:hover .dropdown-menu{
    display: block;
    position: absolute;
    margin-left: -23px;
    top: 100%;
    width: 210%;
    background-color: var(--background-color);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dropdown-menu-bhms{
    display: none;
    
}
.dropdown-menu-bhms ul li{
    display: block;
    padding: 8px;
}
nav ul li:hover .dropdown-menu-bhms{
    display: block;
    position: absolute;
    margin-left: -23px;
    top: 100%;
    width: 190px;
    background-color: var(--background-color);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media screen and ( max-width:756px ){
    nav ul li{
        display: none;
    }
    .toggle{
        display: block; 
        cursor: pointer;  
    }

}

@media screen and ( max-width:456px ){
    nav ul li{
        display: none;
    }
    .toggle{
        display: block; 
        cursor: pointer;  
        
    }

}
