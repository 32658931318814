.advisor{
    width: 100%;
    min-height: 140vh;
}
.advisor-title{
    margin: 5% 5%;
    text-align: center;
}
.advisor-title h1{
    color: var(--primary-color);
}
.advisor-title h5{
    font-size: var(--detailText);
    color: var(--text-clr);
    margin-top: 2%;
    margin-bottom: 2%;
}
.advisor-title img{
   margin-top: 5%;
}
.advisor-main{
    display: flex;
    width: 90%;
    margin: 80px auto;
    align-items: center;
    justify-content: space-between;
}
.advisor-img img{
    width: 100%;
    border-radius: 10px;
    display: block;
}
.advisor-img{
    flex-basis: 25%;
    text-align: center;
}
.advisor-img h2{
    font-size: 20px;
    margin-top: 3%;
}
.advisor-img p{
    margin-top: 2%;
}
.advisor-img h6{
    color: var(--text-clr);
    font-size: 12px;
    margin-top: 2%;
    margin-bottom: 3%;
}
.advisor-img i{
    margin-left: 4%;
}

@media screen and (max-width:756px){
    .advisor-main{
        display: inline;
        width: 70%;
        margin: 80px auto;
        align-items: center;
        justify-content: space-between;
    }
    .advisor-img img{
        width: 60%;
        border-radius: 10px;
        display: block;
        margin: 10% 20%;
    }
}


@media screen and (max-width:486px){
    .advisor-main{
        display: inline;
        width: 90%;
        margin: 80px auto;
        align-items: center;
        justify-content: space-between;
    }
    .advisor-img img{
        width: 80%;
        border-radius: 10px;
        display: block;
        margin: 5% 10%;
    }
}

