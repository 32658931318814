.update{
    width: 100%;
    min-height: 110vh;
    margin-top: 8%;
}
.update-title{
    font-size: 1rem;
    text-align: center;
    padding-top: 3%;
    color: var(--primary-color);
}
.update-main{
    overflow: hidden;
    display: flex;
    width: 200%;
    margin: auto;
    min-height: 70vh;
}
.update-box{
    text-align: center;
    flex-basis: 50%;
    margin: 2% 2%;
    background-color:rgb(209, 238, 180) ;
}
.update-box img{
    margin-top: 2%;
}
.update-box h4{
    margin-top: 2%;
}
.update-box h6{
    margin-top: 2%;
    font-size: 12px;
}
.update-box p{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    font-size: 14px;
    text-align: justify;
}

