.services{
    width: 100%;
    min-height: 160vh;
    background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/corp-services.jpg');
    background-size: cover;
    background-position: center;
}
.service-title{
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    color: white;
}
.service-title h5{
    font-size: var(--detailText);
    letter-spacing: 2px;
    margin-top: 2%;
}
.service-title img{
    margin-top:8%;
}
.service-main{
    display: flex;
    margin:3% 3% 3% 3%;
    min-height: 55vh;
   
}
.service1{
    background: var(--background-color);
    margin: 3% 3% 3% 3%;
    max-width: 50%;
    min-height: 48vh;
    flex-basis: 45%;
}
.service1 i{
    margin-left: 5%;
    font-size: 35px;
    color: var(--primary-color);
}
.btnn{
	background:rgb(28, 112, 160);
	color: black;
	padding: 10px 15px;
	font-size: 14px;
	border-radius: 30px;
	cursor: pointer;
	border: 0;
	outline: 0;
    justify-content: right;
    margin-left: 5%;
    margin-top: 8%;
}
.service1 h3{
    margin-top: 5%;
    margin-left: 5%;
    color: var(--primary-color);
}
.service1 p{
    margin: 5% 5% 5% 5%;
    text-align: justify;
    color: var(--text-clr);
}

@media screen and (max-width:468px){
    .services{
        width: 100%;
        min-height: 160vh;
    }
    .service-main{
        display: inline-block;
        margin:3% 3% 3% 3%;
        min-height: 80vh;
       
    }
    .service1{
        background-color: var(--background-color);
        margin: 3% 3% 3% 3%;
        max-width: 95%;
        min-height: 35vh;
    }
    .service1 h3{
        margin-top: 10%;
        margin-left: 5%;
    }
    .service1 p{
        margin: 5% 5% 5%;
        text-align: justify;
    }
    
}

@media screen and (max-width:768px){
    .services{
        width: 100%;
        min-height: 160vh;
    }
    .service-main{
        display: inline-block;
        margin:3% 3% 3% 3%;
        min-height: 80vh;
       
    }
    .service1{
        background-color: var(--background-color);
        margin: 3% 3% 3% 3%;
        max-width: 95%;
        min-height: 35vh;
    }
    .service1 h3{
        margin-top: 5%;
        margin-left: 5%;
    }
    .service1 p{
        margin: 5% 5% 5% 5%;
        text-align: justify;
    }
    
}