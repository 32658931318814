.Header{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/bgg.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    justify-content: center;
    display: flex;
    align-items: center;
   
}
.head-text{
    text-align: center;
    max-width: 800px;
}
.head-text h1{
    font-size: 60px;
    font-weight: 600;
    letter-spacing: 10px;
}
.head-text p{
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
    letter-spacing: 10px;
}

@media screen and ( max-width:991px){
    .Header{
        width: 100%;
        min-height: 100vh;
        background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/bgg.jpg');
        background-size:cover;
        background-position: center;
        color: #fff;
        justify-content: center;
        display: flex;
        align-items: center;
       
    }
    .head-text h1{
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 10px;
    }

    .head-text p{
        max-width: 700px;
        margin: 10px auto 20px;
        line-height: 1.4;
        letter-spacing: 10px;
}
}


@media screen and ( max-width:468px){
    .Header{
        width: 100%;
        min-height: 100vh;
        background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/bgg.jpg');
        background-size: cover;
        background-position: center;
        color: #fff;
        justify-content: center;
        display: flex;
        align-items: center;
       
    }
    .head-text h1{
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 10px;
    }

    .head-text p{
        max-width: 700px;
        margin: 10px auto 20px;
        line-height: 1.4;
        letter-spacing: 10px;
}
}
