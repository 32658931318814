.primary-btn{
  background: var(--primaryClr);
  padding: 8px 15px;
  font-size: var(--bodyText);
  color:var(--white);
  text-align: center;
  position: relative;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  border-radius: 4px;
}
.primary-btn:hover{
  transform: translateY(-3px);
}
