.footer{
    width: 100%;
    min-height: 90vh;
    margin-top: 5%;
}
.footer-title{
    width: 100%;
    text-align: center;
    background-color: var(--primary-color);
    min-height: 35vh;
}
.footer-title i{
    margin-right: 3%;
    font-size: 40px;
}
.footer-title h1{
    padding-top: 5%;
    padding-bottom: 2%;
    color: white;
}
.footer-main{
    display: flex;
}
.footer-text{
    padding: 5% 5%;
    color: var(--text-color);
    margin: 1% 1%;
    width: 32%;
}
.footer-text h5{
    margin-bottom: 8%;
    margin-top: 8%;
    font-size: 18px;
}
.footer-text p{
    margin-bottom:3%;
}
.footer-text i{
     margin-right: 4%;
}
.footer-text li {
    list-style: none;
}
.footer-bar p{
 text-align: center;
 margin-top: 2%;
 margin-bottom: 2%;
 color: var(--text-color);
}
.footer-text li i{
    padding: 5% 4%;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 4%;
}
.footer-text li {
    margin-bottom: 2%;
}
.footer-text li a:hover{
    color: var(--primary-color);
}
.footer-text i:hover{
    color: blue;
    
}
@media screen and (max-width:586px){
    .footer-main{
        display: inline-block;
    }
    .footer-text{
        padding: 5% 5%;
        color: var(--text-color);
        margin: 1% 1%;
        width: 100%; 
    }
}
