.aboutus{
    width: 100%;
    min-height: 100vh; 
    margin-top: 8%;
}
.about-head{
    text-align: center;
    justify-content: center;
    margin-top: 4%;
}
.about-head h1{
    color: var(--primary-color);
}
.about-head h5{
    font-size: var(--detailText);
    color: var(--text-clr);
    margin-top: 2%;
    margin-bottom: 2%;
}
.about-main{
    display: flex;
    margin-top: 5%;
    min-height: 75vh;
    
}
.about1{
    width: 50%;
    margin: 10px 10px;
    background-color: rgb(244, 248, 248);
}
.about1 .btn{
    margin-left: 4%;
}
.about2{
    width: 50%;
    margin: 10px 10px;
    background-color: rgb(244, 248, 248);
    text-align: justify;
}
.about-text{
   padding: 10px 10px;
   margin: 5% 5%;
}
.about-text h3{
    margin-left: 1%;
}
.about-text i{
    margin-right: 2%;
    font-weight: 600;
}
.about1 p{
    margin: 5% 5%;
    text-align: justify;
    color: var(--text-clr);
}
.about2 p{
    color: var(--text-clr);
}
.about1 h4{
    font-size: 18px;
    margin: 5% 5%;
    text-align: justify;
    color: var(--primary-color)
}
.about1 h1{
    margin: 5% 5%;
    text-align: justify;
    color: var(--primary-color);
}
.about-text h3{
    margin-bottom: 2%;
    color: var(--primary-color)
}
.grow-main{
    display: flex;
    margin-top: 5%;
    min-height: 75vh;
}
.grow1{
    width: 50%;
    margin: 10px 1px;
    background-color: var(--light-color);
}
.grow2{
    width: 50%;
    margin: 10px 0;
    background-color: var(--light-color);
    text-align: justify;
}
.grow-text{
    padding: 5px 5px;
    margin: 5% 5%;
}
.grow-text h3{
    text-align: justify;
    margin: 5% 5%;
    color: black;
}
.grow-text p{
    margin: 5% 5%;
    text-align: justify;
    color: var(--text-clr);
}
.choose-main{
    display: flex;
    margin-top: 5%;
    min-height: 75vh;
    
}
.choose1{
    width: 50%;
    margin: 10px 10px;
    text-align: justify;
    background: linear-gradient(rgba(3, 4, 71, 0.7),rgba(3, 4, 71, 0.7)), url('../../assets/images/images/corp-why.jpg');
    background-size: cover;
    background-position: center;
    color: azure;
}
.choose2{
    width: 50%;
    margin: 10px 10px;
    background-color: var(--background-color);
    text-align: justify;
}
.choose2 img{
     margin-top: 4%;
     margin-left: 45%;
}
.choose-text{
    padding: 5px 5px;
    margin: 5% 5%;
}
.choose-text i{
    font-size: 30px;
    margin-right: 3%;
}
.choose1 p{
    padding: 5px 5px;
    margin: 5% 8%; 
    text-align: justify;
}
.choose2 p{
    padding: 5px 5px;
    margin: 5% 5%; 
    text-align: justify;
    color: var(--text-clr);
}
.choose1 h1{
    padding: 5px 5px;
    margin: 5% 5%; 
    text-align: justify;
}
.choose2 h1{
    padding: 5px 5px;
    margin: 5% 5%; 
    text-align: justify;
    color: var(--primary-color);
}
.choose2 form input, .choose2 form textarea{
    display: block;
    width: 80%;
    background: #EBECFE;
    padding: 10px;
    border: 0;
    outline: 0;
    margin-bottom: 3%;
    border-radius: 5px;
    margin-top: 3%;
    margin-left: 5%;
}
.choose2 form label{
    margin-left: 5%;
}

.choose2 .btn{
    margin-left: 5%;
}
.choose2 .btn:hover{
    transition: 0.5s;
    transform: translateX(1.5px);
}
.grow-text h3{
    padding: 5px 5px;
    margin: 5% 5%; 
    text-align: justify;
}
.grow-text i{
    margin-right: 2%;
    font-size: 30px;
    color: var(--primary-color);
}

@media screen and ( max-width:486px) {
    .about-head{
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    .about-main{
        display: inline-block;
        width: 100%;
        background-color: rgb(139, 170, 160);
        margin-top: 5%;
        min-height: 75vh;
        
    }
    .about1{
        width: 95%;
        margin: 10px 10px;
        background-color: rgb(212, 250, 250);
    }
    .about2{
        width: 95%;
        margin: 10px 10px;
        background-color: rgb(212, 250, 250);
        text-align: justify;
    }
    .grow-main{
        display: inline-block;
        margin-top: 5%;
        min-height: 75vh;
    }
    .grow1{
        width: 95%;
        margin: 10px 1px;
        background-color: rgb(170, 197, 219);
    }
    .grow2{
        width: 95%;
        margin: 10px 0;
        background-color: rgb(170, 197, 219);
        text-align: justify;
    }
    .choose-main{
        display: inline-block;
        margin-top: 5%;
        min-height: 75vh;
    }
    .choose1{
        width: 95%;
        margin: 10px 10px;
        text-align: justify;
        background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/corp-why.jpg');
        background-size: cover;
        background-position: center;
    }
    .choose2{
        width: 95%;
        margin: 10px 10px;
        text-align: justify;
    }
}

@media screen and ( max-width:756px) {
    .about-head{
        text-align: center;
        justify-content: center;
        margin-top: 5%;
    }
    .about-main{
        display: inline-block;
        width: 100%;
        background-color: rgb(139, 170, 160);
        margin-top: 5%;
        min-height: 75vh;
        
    }
    .about1{
        width: 95%;
        margin: 10px 10px;
        background-color: rgb(212, 250, 250);
    }
    .about2{
        width: 95%;
        margin: 10px 10px;
        background-color: rgb(212, 250, 250);
        text-align: justify;
    }
    .grow-main{
        display: inline-block;
        margin-top: 5%;
        min-height: 75vh;
    }
    .grow1{
        width: 95%;
        margin: 10px 1px;
        background-color: rgb(170, 197, 219);
    }
    .grow2{
        width: 95%;
        margin: 10px 0;
        background-color: rgb(170, 197, 219);
        text-align: justify;
    }
    .choose-main{
        display: inline-block;
        margin-top: 5%;
        min-height: 75vh;
    }
    .choose1{
        width: 95%;
        margin: 10px 10px;
        text-align: justify;
        background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/corp-why.jpg');
        background-size: cover;
        background-position: center;
    }
    .choose2{
        width: 95%;
        margin: 10px 10px;
        text-align: justify;
    }
}


