.why-us{
    width: 100%;
    min-height: 110vh;
    background: linear-gradient(rgba(8, 0, 58, 0.7),rgba(8, 0, 58, 0.7)), url('../../assets/images/images/testimonials.jpg');
    background-size: cover;
    background-position: center;
}
.why-title{
    color: white;
    text-align: center;
    min-height: 35vh;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10%;
}
.why-title h1{
    padding-top: 3%;
    padding-bottom: 3%;
}
.why-title img{
    padding-top: 6%;
}

.why-main h3{
  color: beige;
  margin: 2% 2% ;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 500;
  margin-left: 8%;
}
.why-main i{
    margin-right: 4%;
  }


@media screen and (max-width:991px)
{
    .why-title{
        color: white;
        text-align: center;
        min-height: 35vh;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 10%;
    }
    .why-title h1{
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .why-main h3{
        color: beige;
        margin: 2% 2% ;
        font-size: 18px;
        font-family: sans-serif;
        font-weight: 400;
        margin-left: 8%;
      }
      .why-main i{
        margin-right: 4%;
      }
}
@media screen and (max-width:768px)
{
    .why-title{
        color: white;
        text-align: center;
        min-height: 35vh;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 10%;
    }
    .why-title h1{
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .why-main h3{
        color: beige;
        margin: 2% 2% ;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 300;
        margin-left: 8%;
      }
      .why-main i{
        margin-right: 4%;
      }
}
@media screen and (max-width:468px)
{
    .why-title{
        color: white;
        text-align: center;
        min-height: 35vh;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 10%;
    }
    .why-title h1{
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .why-main h3 {
        color: beige;
        margin: 2% 2% ;
        font-size: 14px;
        font-family: sans-serif;
        font-weight: 300;
        margin-left: 8%;
        justify-content:justify;
      }
      .why-main i{
        margin-right: 4%;
      }
}