@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");


:root{
	--primary-color: #085399;
	--light-color:rgba(222, 240, 255, 0.973);
	--highlighting-color: #5a5a5a;
	--background-color: #f0f0f0;
	--text-color: black;
	--text-clr: #2e2e2e;
	--text-color1: white;


	--detailText: 14px;
	--mainText: 16px;
	--subTitle: 20px;
	--heading:24px;
	--title: 30px;
	--font-family:"Lato", sans-serif;
	--font-family1:"Libre Baskerville", serif;
}
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Outfit", sans-serif;
}
body{
	background: #f5f5f5;
}
a{
	text-decoration: none;
	color: inherit;
	line-height: 1;
	cursor: pointer;
}
.container{
	padding-left: 1%;
	padding-right: 1%;
}
.btn{
	background:rgb(144, 195, 224);
	color: black;
	padding: 14px 25px;
	font-size: 16px;
	border-radius: 30px;
	cursor: pointer;
	border: 0;
	outline: 0;
}
.btn:hover{
   background: #4c769c;
}